import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import ProjectsList from "../sections/Projects/ProjectsList";
import ProjectsFilter from "../sections/Projects/ProjectsFilter";
import Pagination from "../components/Pagination";
import DefaultHelmet from "../seo/DefaultHelmet";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";

const Projects = ({ pageContext }) => {
	const {
		projects,
		technologies,
		currentTechnologySlug,
		projectsPerPage,
		totalCount,
		currentPage,
		pagesCount,
		categoryOriginPath,
		pageTitle,
		pageHeader,
	} = pageContext;

	useBeaconWidget(beaconWidgetIds.GENERAL);

	return (
		<DefaultLayout>
			<DefaultHelmet
				title={pageTitle + " | Projects page " + currentPage}
				description={"UTD Projects, category: " + pageTitle + ", page: " + currentPage}
				keywords={"UTD, Shopify, Shopify themes, Shopify apps, eCommerce"}
			/>

			<ProjectsFilter
				title={pageTitle}
				technologies={technologies}
				currentTechnologySlug={currentTechnologySlug}
				pageHeader={pageHeader}
			/>

			<ProjectsList
				projects={projects}
				totalCount={totalCount}
				projectsPerPage={projectsPerPage}
			/>

			<Pagination
				pagesCount={pagesCount}
				currentPage={currentPage}
				originalPath={categoryOriginPath}
			/>
		</DefaultLayout>
	)
};

export default Projects;
