import React from "react";
import PropTypes from "prop-types";
import ProjectWrapperTwoRows from "../../components/ProjectsList/ProjectWrapperTwoRows";
import ProjectWrapperOneRow from "../../components/ProjectsList/ProjectWrapperOneRow";

const ProjectsList = ({ projects }) => {
	const projectsArray = [ ...projects ];
	const projectsWrappers = [];

	while (projectsArray.length) {
		projectsWrappers.push(projectsArray.splice(0, 3));
	}

	return (
		<div className="projects-list">
			<div className="projects-list__container container container--mobile-small">
				{projectsWrappers.map((projectsWrapper, index) => {
					if (index % 2 === 0 || projectsWrapper.length < 3) {
						return (
							<ProjectWrapperTwoRows
								key={index}
								projectsWrapper={projectsWrapper}
							/>
						);
					}

					return (
						<ProjectWrapperOneRow
							key={index}
							projectsWrapper={projectsWrapper}
						/>
					);
				})}
			</div>
		</div>
	);
};

ProjectsList.propTypes = {
	projects: PropTypes.array.isRequired,
	projectsPerPage: PropTypes.number.isRequired,
	totalCount: PropTypes.number.isRequired
};

export default ProjectsList;
