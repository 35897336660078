import React, {useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import PageHeader from "../../components/PageHeader";

const ProjectsFilter = ({title, technologies, currentTechnologySlug, pageHeader}) => {
    const activeFilter = useRef(null);
    const filtersWrapper = useRef(null);


    useEffect(() => {
        const activeFilterEl = activeFilter.current;

        if (!activeFilterEl) {
            return;
        }

        activeFilterEl.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        });
    }, []);
    useEffect(() => {
        if (!activeFilter.current && !filtersWrapper.current) return;

        const x = activeFilter.current.offsetLeft - window.innerWidth / 2 + activeFilter.current.offsetWidth / 2;
        filtersWrapper.current.scrollTo(x, 0);
    });

    return (
        <div className="projects-filter">
            <div className="projects-filter__wrapper">
                <div className="container">
                    <PageHeader
                        {...pageHeader}
                        title={pageHeader?.title || title}
                        implemented={true}
                        className={"projects-filter__title section-title section-title--large"}
                        isGeneral={true}
                    />
                </div>

                <div className="container projects-filter__list-container">
                    {Boolean(technologies.length) && (
                        <div className="projects-filter__list-wrapper" ref={filtersWrapper}>
                            <div className="projects-filter__list">
                                <div className="projects-filter__items">
                                    {currentTechnologySlug === null ? (
                                        <Link to={`/projects/category/all/`}
                                              className="projects-filter__item projects-filter__item--active" ref={activeFilter}>
                                            All Projects
                                        </Link>
                                    ) : (
                                        <Link to={`/projects/category/all/`} className="projects-filter__item">
                                            All Projects
                                        </Link>
                                    )}

                                    {technologies.map(({id, name, slug, acfTechnology}) => {
                                        const isActiveLink = slug === currentTechnologySlug;

                                        const icon = acfTechnology?.icon;

                                        return (
                                            isActiveLink ? (
                                                <Link key={id} to={`/projects/category/${slug}/`} ref={activeFilter}
                                                      className={`projects-filter__item projects-filter__item--active ${icon ? '' : 'no-icon'}`}>
                                                    {icon && (
                                                        <span className="icon-wrapper"
                                                              dangerouslySetInnerHTML={{__html: icon}}/>
                                                    )}

                                                    {name}
                                                </Link>
                                            ) : (
                                                <Link key={id} to={`/projects/category/${slug}/`}
                                                      className={`projects-filter__item ${icon ? '' : 'no-icon'}`}>
                                                    {icon && (
                                                        <span className="icon-wrapper"
                                                              dangerouslySetInnerHTML={{__html: icon}}/>
                                                    )}

                                                    {name}
                                                </Link>
                                            )
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ProjectsFilter.propTypes = {
    title: PropTypes.string.isRequired,
    technologies: PropTypes.array,
    currentTechnologySlug: PropTypes.string
};

export default ProjectsFilter;
